import * as React from "react"
import Form from "../form/Form"

export default function HelpChoosing(props) {
  const { data, classAdd } = props

  return (
    <div className={classAdd ? `helpChoosing ${classAdd}` : "helpChoosing"}>
      <div className="helpChoosing__cont">
        <div className="helpChoosing__text">
          <h2>
            {data?.title
              ? data.title
              : "Поможем в выборе преподавателя и курса"}
          </h2>

          <p>
            {data?.subtitle
              ? data.subtitle
              : "Остались вопросы? Не знаете, что выбрать? Оставьте свой номер, мы позвоним и ответим на все ваши вопросы"}
          </p>
        </div>

        <Form button={data} classAdd={"helpChoosing__form"} />
      </div>
    </div>
  )
}
