import * as React from "react"
import { useState } from "react"
import { Link, graphql } from "gatsby"
import { AnimatePresence } from "framer-motion"

import Img from "react-cool-img"
import "./photoDetail.scss"

import Layout from "../../components/Layout"
import DoubleBlock from "../../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../../components/sideBarMenu/SidebarMenuAbout"
import { STRAPI_API } from "../../consts/api"
import { MENU_LINKS } from "../../consts/MenuLinks"
import HelpChoosing from "../../components/offers/HelpChoosing"
import FormPopup from "../../components/popups/FormPopup"
import GalleryPopup from "../../components/popups/GalleryPopup"
import {SEO} from "../../components/seo";

const PhotoDetailTeam = ({ data, location }) => {
  const { strapiPhotoPage } = data

  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  const [formPopup, setFormPopup] = useState(false)

  return (
    <div>
      <SEO title={strapiPhotoPage?.titleDesc?.pageTitle} description={strapiPhotoPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="photoDetailPage photoDetail" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <div className="link-back">
            <Link to={MENU_LINKS.photo}>Назад</Link>
          </div>

          {
            // HEAD
            strapiPhotoPage?.teamTitle && (
              <h2 className="photoTitle">{strapiPhotoPage.teamTitle}</h2>
            )
          }

          {
            // GALLERY
            strapiPhotoPage?.teamGallery && (
              <div className="m60_40">
                <ul className="photoDetail__gallery photoDetail__gallery--team">
                  {strapiPhotoPage.teamGallery.map((item, i) => {
                    return (
                      <li
                        onClick={() => {
                          setPopUpGallery(true)
                          setGalleryElem(i)
                        }}
                        key={i}
                      >
                        <Img
                          className="photoDetail__photo"
                          src={STRAPI_API + item.url}
                          alt="img"
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          }
          {
            // OFFER
            strapiPhotoPage?.offer && (
              <HelpChoosing classAdd="m140" data={strapiPhotoPage.offer} />
            )
          }
        </DoubleBlock>
      </Layout>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpGallery && strapiPhotoPage?.teamGallery && (
          <GalleryPopup
            open={popUpGallery}
            setOpen={setPopUpGallery}
            galleryElem={galleryElem}
            data={strapiPhotoPage.teamGallery}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiPhotoPage {
      teamGallery {
        url
      }
      teamTitle
      offer {
        title
        subtitle
        button
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
  }
`

export default PhotoDetailTeam
