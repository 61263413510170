import * as React from "react"
import SidebarMenu from "./SidebarMenu"
import { MENU_LINKS } from "../../consts/MenuLinks"

export default function SidebarMenuAbout(props) {
  const { setFormPopup, location } = props

  const sidebarList = [
    { name: "О школе", href: MENU_LINKS.about },
    { name: "Фото школы", href: MENU_LINKS.photo },
    { name: "Преподаватели", href: MENU_LINKS.teachers },
    { name: "Pioneer DJ Family", href: MENU_LINKS.family },
    { name: "Новости и акции", href: MENU_LINKS.news },
    { name: "Отзывы", href: MENU_LINKS.review },
    { name: "Контакты", href: MENU_LINKS.contacts },
    // {name: 'Документы', href: MENU_LINKS.documents},
  ]

  return (
    <SidebarMenu
      location={location}
      setFormPopup={setFormPopup}
      sidebarList={sidebarList}
    />
  )
}
