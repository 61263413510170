import * as React from "react"
import "./doubleBlock.scss"

export default function DoubleBlock(props) {
  const { menu, children } = props

  return (
    <div className="doubleBlock">
      <div className="container">
        <div className="doubleBlock__block">
          <div className="doubleBlock__sidebar">{menu}</div>

          <div className="doubleBlock__cont">{children}</div>
        </div>
      </div>
    </div>
  )
}
