import * as React from "react"
import {Link} from "gatsby"
import {motion} from "framer-motion"

export default function SidebarMenu(props) {
    const {sidebarList, setFormPopup} = props
    const isBrowser = typeof window !== "undefined"

    return (
        <motion.div className="sidebarMenu" transition={{duration: 0}}>
            <ul className="sidebarMenu__list">
                {sidebarList.map((item, index) => (
                    <li key={index}>
                        <Link
                            className={`${
                                isBrowser && String(window.location.href).includes(item.href)
                                    ? "active"
                                    : ""
                            }`}
                            to={item.href}
                        >
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>

            <div className="sidebarMenu__link">
                <button className="button-empty" onClick={setFormPopup}>
                    <span className="flare"></span>
                    <span>Оставить заявку</span>
                </button>
            </div>
        </motion.div>
    )
}
